export const environment = {
	production: false,
	env: 'staging',
	giftAPIURL: 'https://giftcardtagme-dev.azurewebsites.net',
	livelo: {
		auth: 'https://acesso-uat.livelo.com.br',
		clientId: 'tagme-bbgastronomia',
		redirectUri: 'https://bb-gastronomia-stg.tagme.com.br',
	},
	channelsAPIURL: 'https://channelsapi-stg.tagme.com.br',
	googleMapsAPIToken: 'AIzaSyAZ-N7OLjJFVViV6m4Rk5BV7eeuw97DJx8',
	liveMenu: {
		url: 'https://live-menu-dev.tagme.com.br/menu',
	},
	conciergeURL: 'https://kind-ocean-086d08f10.1.azurestaticapps.net/concierge',
}
