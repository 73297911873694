export const environment = {
	production: false,
	env: 'staging',
	giftAPIURL: 'https://giftcardtagme-dev.azurewebsites.net',
	livelo: {
		auth: 'https://acesso-uat.livelo.com.br',
		clientId: 'tagme-bbgastronomia',
		redirectUri: 'https://bb-gastronomia-stg.tagme.com.br',
	},
	channelsAPIURL: 'https://channelsapi-stg.tagme.com.br',
	googleMapsAPIToken: 'AIzaSyA1p9RdPX2xq2MKmgI2_jCxHqbDs6hGs0E',
	liveMenu: {
		url: 'https://live-menu-dev.tagme.com.br/menu',
	},
}
